import * as React from "react"
import Page3dArSolution from "../../components/Page3dArSolution/Page3dArSolution"
import Seo from "../../components/seo"
import "../../assets/css/style.css"
import "../../assets/css/layout.css"
import "../../assets/css/vto-style.css"
import { organizationSchema } from "../../const/schemas"
import { languages } from "../../const/languages"

const ServicesPage = () => <Page3dArSolution lang={languages.PL}/>

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Virtual try-on solution for multiple platforms"
        description="Revolutionize your customers' virtual shopping experience with Virtual Try-on solution from AR-Labs.io on Android & iOS smartphones and tablets, integrate it into your website or use it with digital displays."
        canonicalURL="https://ar-labs.io/pl/demo/3d-ar-solution"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export default ServicesPage