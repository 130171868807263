import React from "react"
import Image from "./image"
import Button from "./button/button"

import glassesFront from "../assets/models/glasses2D/front.png";
import glassesLeft from "../assets/models/glasses2D/left.png";
import glassesRight from "../assets/models/glasses2D/right.png";
import glassesLenses from "../assets/models/glasses2D/lenses.png";

import sunglassesFront from "../assets/models/sunglasses2D/front.png";
import sunglassesLeft from "../assets/models/sunglasses2D/left.png";
import sunglassesRight from "../assets/models/sunglasses2D/right.png";
import sunglassesLenses from "../assets/models/sunglasses2D/lenses.png";

import glassesAlbedo from "../assets/models/glasses3D/albedo.png";
import glassesMetallic from "../assets/models/glasses3D/metallic.png";
import glassesModel from "../assets/models/glasses3D/model.fbx";

import sunglassesAlbedo from "../assets/models/sunglasses3D/albedo.png";
import sunglassesMetallic from "../assets/models/sunglasses3D/metallic.png";
import sunglassesModel from "../assets/models/sunglasses3D/model.fbx";

import jewelryAlbedo from "../assets/models/jewelryGlasses3D/albedo.png";
import jewelryMetallic from "../assets/models/jewelryGlasses3D/metallic.png";
import jewelryModel from "../assets/models/jewelryGlasses3D/model.fbx";


export const TryOnProductType = {
  GLASSES_3D: "glasses3d",
  SUNGLASSES_3D: "sunglasses3d",
  JEWELRY_GLASSES_3D: "jewelryglasses3d",
  GLASSES_2D: "glasses2d",
  SUNGLASSES_2D: "sunglasses2d"
}

const is2D = (type) => type.slice(-2) === "2d";

const productFiles = {
  [TryOnProductType.GLASSES_2D]: {
    front: glassesFront,
    left: glassesLeft,
    right: glassesRight,
    lenses: glassesLenses
  },
  [TryOnProductType.SUNGLASSES_2D]: {
    front: sunglassesFront,
    left: sunglassesLeft,
    right: sunglassesRight,
    lenses: sunglassesLenses
  },
  [TryOnProductType.GLASSES_3D]: {
    albedo: glassesAlbedo,
    metallic: glassesMetallic,
    model: glassesModel
  },
  [TryOnProductType.SUNGLASSES_3D]: {
    albedo: sunglassesAlbedo,
    metallic: sunglassesMetallic,
    model: sunglassesModel
  },
  [TryOnProductType.JEWELRY_GLASSES_3D]: {
    albedo: jewelryAlbedo,
    metallic: jewelryMetallic,
    model: jewelryModel
  },
};

const loadTryOn = (id) => {
  if (document.getElementById("load-arlab")) document.getElementById("load-arlab").outerHTML = "";
  if (document.getElementById("arlab")) document.getElementById("arlab").outerHTML = "";

  if (window.arlab?.q) window.arlab.q = [];

  const script = document.createElement("script");
  script.id = "load-arlab";
  script.async = true;
  script.innerHTML = `
    (function (w,d,s,o,f,js,fjs) {
      w['arlab-site-widgets']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
      if (d.getElementById(o)) return;
      js = d.createElement(s); 
      fjs = d.getElementsByTagName(s)[0];
      js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'arlab', 'https://connect.ar-labs.io/v2/widget.js'));
    arlab('demo', { id: "${id}", language: 'en' });
  `;
  document.head.appendChild(script);
};

const TryOnProduct = ({ title, imageName, buttonName, imageAlt, type }) => {
  const id = "webfit-modal-container-" + type;
  return (
    <div className="border-image-gray">
      <div className="grid-container products-card demo-card">
        <h3 className="bold">
          {title}
        </h3>
        <Image
          name={imageName}
          height={143}
          width={274}
          alt={imageAlt}
          style={{ margin: "20px 0 40px 0" }}
        />
        <Button className="button-dark button-small" text={buttonName} onClick={() => loadTryOn(id)}/>
        <div 
          id={id}
          model-url={productFiles[type].model}
          albedo-url={productFiles[type].albedo}
          metallic-url={productFiles[type].metallic}
          normal-url={productFiles[type].normal}
          front-url={productFiles[type].front}
          lenses-url={productFiles[type].lenses}
          left-url={productFiles[type].left}
          right-url={productFiles[type].right}
          scale={1}
          rotation={0}
          position-y={0}
          position-z={0}
          ear-rotation={0}
          is-2d={is2D(type).toString()}
        >
          <div id={id + "-modal"}/>
          <div id={id + "-button"} style={{ display: "none" }}/>
        </div>
      </div>
    </div>
  )
}

export default TryOnProduct
