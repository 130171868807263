import * as React from "react"
import TryOnProduct, { TryOnProductType } from "../../common/tryOnProduct";
import { t } from "../../utils/translationUtils"
import { languages } from "../../const/languages"

const Demo = ({ lang = languages.EN }) => {
  const [text, setText] = React.useState();
  React.useEffect(() => {
    setText(() => {return require(`../../translations/demo/${lang}.json`).page3d.demo})
  }, [])

  return (
    text &&
    <div className="card" id="products">
      <div className="container container-demo-cards">
        <div className="grid-template-3-columns products demo-cards">
          <TryOnProduct 
            title={<>{t(text.items[0])}</>}
            imageName="demoGlasses"
            buttonName={t(text.button)}
            imageAlt="Reading Glasses"
            type={TryOnProductType.GLASSES_3D}
          />
          <TryOnProduct 
            title={<>{t(text.items[1])}</>}
            imageName="demoSunglasses"
            buttonName={t(text.button)}
            imageAlt="Sunglasses"
            type={TryOnProductType.SUNGLASSES_3D}
          />
          <TryOnProduct 
            title={<>{t(text.items[2])}</>}
            imageName="demoJewelryGlasses"
            buttonName={t(text.button)}
            imageAlt="Jewelry Glasses"
            type={TryOnProductType.JEWELRY_GLASSES_3D}
          />
        </div>
      </div>
    </div>
  )
}

export default Demo
